.analytics {
    padding: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1200px;
    background-color: var(--kanban-background-color);
}

.chart {
    background-color: var(--card-background-color);
    border-radius: 10px;
    padding: 15px;
    box-shadow: var(--card-box-shadow);
    border: 1px solid var(--card-border-color);
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: initial;
    color: var(--text-primary);
    row-gap: 10px;
    height: auto;
}

.chart-title {
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
}

.analytics-body {
    width: 100%;
    padding: 0px 50px 50px 50px;
}

.analytics-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 50px 12px 50px;
}

.analytics-header .title {
    font-size: 40px;
    font-weight: 500;
    color: var(--text-primary);
    margin-left: 10px;
}

.analytics-body .chart {
    width: 100%;
}

.filter-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    background-color: var(--card-background-color);
    box-shadow: var(--card-box-shadow);
    border: 1px solid var(--card-border-color);
    border-radius: 10px;
    padding: 0px 2px;
}

.range-bar {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    column-gap: 0px;
    padding: 0px;
    border: var(--filter-bar-button-border);
    border-radius: 5px;
    margin: 5px;
    overflow: hidden;
}

.filter-bar-left {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    column-gap: 10px;
    padding: 0px;
    margin: 5px;
}

.filter-bar-right {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    column-gap: 10px;
    padding: 0px;
    margin: 5px;
}

.range-bar .range-option {
    padding: 4px 12px;
    font-size: 13px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
    color: var(--topbar-button-secondary-color);
    background: var(--topbar-button-background-color-filled);
}

.range-bar .range-option:hover {
    background: var(--topbar-button-hover-background-color);
}

.range-option.selected {
    background: var(--range-option-selected-background);
    color: var(--text-primary);
    font-weight: 600;
}

.range-option.selected:hover {
    background: var(--range-option-selected-background);
    color: var(--text-primary);
}


.range-bar .divider {
    border-left: 1px solid var(--divider-color);
    height: 27px;
}

.same-line-chart {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
}

.same-row-chart {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
}


.analytics-charts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    width: 100%;
    grid-template-areas:
        "average-task-age-small average-task-age-small tasks-completed-small tasks-completed-small actual-vs-estimated-small actual-vs-estimated-small"
        "bar-chart-1 bar-chart-1 bar-chart-1 bar-chart-2 bar-chart-2 bar-chart-2"
        "bar-chart-1 bar-chart-1 bar-chart-1 bar-chart-2 bar-chart-2 bar-chart-2"
    ;
}

.double-chart {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    width: 100%;
}

.triple-chart {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    width: 100%;
}

.chart.sl {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    padding: 30px;
    row-gap: 6px;
}

.chart .title {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.chart.sl .title:hover {
    cursor: pointer;
    color: var(--primary-color);
}

.chart .value {
    font-size: 40px;
}

.chart .single-title {
    font-size: 20px;
    font-weight: 500;
}

.chart .change-chip {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 3px;
    background: var(--green-chip-background);
    color: var(--green-chip-color);
    padding: 3px 10px;
    border-radius: 9px;
}

.chart .change-chip.orange {
    background: #ffe2d0;
    color: #3d0000;
    cursor: pointer;
}

.chart .title-icon {
    font-size: 20px;
}

.filter-bar-right-item {
    padding: 4px 12px;
    font-size: 13px;
    border: var(--filter-bar-button-border);
    border-radius: 5px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
    color: var(--topbar-button-secondary-color);
}

.filter-bar-right-item:hover {
    background: var(--topbar-button-hover-background-color);
}

.close-container {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
    height: 40px;
    background-color: var(--close-icon-background-2);
    color: var(--close-icon-color-2);
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
}

.close-container:hover {
    background-color: var(--close-icon-hover-background-2);
}

.task-table td {
    text-align: left;
    border-bottom: 1px solid var(--big-button-border);
    padding: 10px;
    max-width: 300px;
    overflow: hidden;
    /* Wrap text */
    word-wrap: break-word;


}

.braindump-oldest-header-checkbox {
    width: 25px;
}

.task-table th {
    text-align: left;
    border-bottom: 1px solid var(--big-button-border);
    /* border-radius: 10px; */
    padding: 10px;
}

.table-container {
    padding: 2px 10px 10px 10px;
}

.task-table {
    border: 1px solid var(--big-button-border);
    width: 100%;
    border-radius: 11px;
    border-collapse: separate;
    border-spacing: 0px;
    color: var(--text-primary);
}

.task-table thead {
    border: 1px solid var(--big-button-border);
    border-radius: 11px;
}

.braindump-oldest-task-label {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.anlaytics-subarea-header {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px;
    column-gap: 15px;
    font-size: 16px;
}

.anlaytics-subarea-header .back {
    font-size: 18px;
    color: var(--primary-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.cleanup-toolbar {
    display: flex;
    align-items: center;
    padding: 10px;
    column-gap: 10px;
    font-size: 16px;
}

.cleanup-title {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.no-data {
    padding: 10px;
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    row-gap: 10px;
    color: var(--text-secondary);
}

.no-data .icon {
    height: 35px;
    width: 35px;
    color: var(--text-secondary);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.braindump-oldest-task-title {
    min-width: 250px;
    color: var(--primary-color);
    font-weight: 500;
    cursor: pointer;
}

.braindump-oldest-task-title:hover {
    color: var(--primary-color-hover);
}