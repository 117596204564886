.label-selector-container {
    display: flex;
    flex-direction: column;

    font-size: 13px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    border-radius: 8px;
}

.label-selector-search {
    padding: 7px;
    border-bottom: 2px solid var(--border-color);
    display: flex;
    align-items: center;
}

.label-selector-search-icon {
    height: 17px;
    margin-right: 5px;
    color: #b1b1b1;
}

.label-selector-search-input {
    border: none;
    outline: none;
    font-size: 13px;
    background-color: transparent;
}

.label-selector-options {
    padding: 5px 0px;
    max-height: 200px;
    overflow-y: auto;
}

.label-selector-option {
    padding: 7px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.label-selector-option-hashtag {
    margin-right: 5px;
}

.label-selector-option:hover {
    background-color: var(--option-hover-background-color);
}

/* Create label styles */

.create-label-container {
    padding: 1em;
}

.label-input-container {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.lable-input-title {
    margin-bottom: .45em;
    font-size: 14px;
}

.label-name-input {
    border-radius: 6px;
    border: var(--search-bar-border);
    padding: 4px 6px;
    width: 100%;
    background: var(--search-bar-background);
}

.color-picker-container {
    position: absolute;
    z-index: 500;
}

.color-picker-color {
    border-radius: 50%;
    height: 15px;
    width: 15px;
}

.color-picker-color-outer {
    cursor: pointer;
    /* padding: 5px; */
    /* background-color: #000000; */
    border-radius: 8px;
    border: 2px solid #d1d1d1;
    /* box-shadow: inset 0 0 0 1px rgb(188 188 201 / 18%); */
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: var(--search-bar-border);
}

.color-picker-color-outer:hover {
    border: var(--search-bar-border-hover);
}

.create-label-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.create-label-cancel {
    margin-right: .5em;
    cursor: pointer;
}

.create-label-create {
    padding: 4px 6px;
    background-color: var(--button-background-color);
    color: var(--button-color);
    cursor: pointer;
    border-radius: 6px;
    border: var(--button-border);
}

.create-label-create:hover {
    color: var(--button-hover-color);
    border: var(--button-hover-border);

}

.create-label-button-container {
    padding: 7px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--button-color);
}

.create-label-button-container.selected {
    background-color: var(--button-hover-background-color);
}

.create-label-button-container:hover {
    background-color: var(--button-hover-background-color);
}

.create-label-icon {
    height: 15px;
    margin-right: 4px;
}

.labels-empty {
    text-align: center;
    padding: 10px;
}

.card-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--text-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
}

.card-no-label {
    display: none;
    font-size: 11px;

    cursor: pointer;
    align-items: center;
    font-weight: 500;
    color: var(--text-secondary);
    font-weight: 500;
}

.card-container:hover .card-no-label {
    display: flex;
}

.card-container:hover .card-no-label:hover {
    display: flex;
    color: var(--text-primary);
}

.card-no-label.visible {
    display: flex;
}

.lm-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    padding: 0px 8px;
}

.lm-title {
    font-size: 16px;
    font-weight: 500;
}

.lm-create-button {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    cursor: pointer;
}

.lm-create-button:hover {
    color: var(--text-primary);
}

.lm-create-button .icon {
    height: 18px;
    margin-right: 5px;
}

.create-label-container.inline {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.label-input-container.inline {
    margin-bottom: 0px;

}

.label-selector-option.selected {
    background-color: var(--option-hover-background-color);
}

.button.primary {
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    background-color: var(--primary-color);
    border-radius: 6px;
    padding: 5px 11px;
    display: inline-block;
    border: 1px solid var(--primary-color);
}

.button.primary:hover {
    background-color: var(--primary-color-hover);
    border: 1px solid var(--primary-color-hover);
}

.button.with-shortcut {
    display: flex;
    align-items: center;
    column-gap: 7px;
    padding: 5px 5px 5px 11px;
}

.button .inline-shortcut {
    display: flex;
    align-items: center;
    background: #8f59eb;
    border-radius: 4px;
    padding: 0px 3px;
    font-size: 10px;
}

.shortcut-small {
    display: flex;
    align-items: center;
    padding: 3px;
}


.color-picker-row {
    display: flex;
    background-color: var(--modal-background-color);
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    column-gap: 5px;
    box-shadow: 0 0 0 1px rgb(188 188 201 / 18%);
}

.color-picker-row .option {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.color-picker-row .option:hover {
    box-shadow: 0 0 0 1px rgb(188 188 201 / 18%);
    border: 1px solid var(--text-primary);
}


.option.palette {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-primary);
    padding: 4px;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgb(188 188 201 / 18%);
    /* border: 1px solid #e0e0e0; */
    background-color: #ff58ec;
    color: #ffffff;
}

.palette-container {
    border-left: 1px solid #c5c5c5;
    padding-left: 8px;
    margin-left: 5px;
}

.custom-color-picker-contianer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgb(188 188 201 / 18%);
    background: var(--modal-background-color);
}

.hexColorInput {
    display: block;
    box-sizing: border-box;
    width: 90px;
    padding: 6px;
    border: var(--settings-input-border);
    border-radius: 4px;
    background: var(--settings-input-background);
    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;
    color: var(--text-primary);
}

.hex-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    margin-top: 11px;
    font-size: 14px;
    font-weight: 600;
    color: #585858;
}

.custom-color-picker-contianer .header {
    display: flex;
    font-family: 'Inter';
    font-size: 16px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.picker-back-button {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--primary-color);
    font-size: 14px;
    cursor: pointer;
}

.picker-back-button .chevron {
    width: 20px;
    height: 20px;
}

.picker-back-button:hover {
    color: var(--text-primary);
}

.option .checked {
    width: 20px;
}