.card-complete-button {
    border: 2px solid #d3d3d3;
    border-radius: 6px;
    color: #afafaf;
    cursor: pointer;
    width: 18px;
    height: 18px;
    padding: 1px;
    margin-top: 4px;
    min-width: 18px;
    min-height: 18px;
}

.card-complete-button.round {
    height: 22px;
    width: 22px;
    border-radius: 50%;
}

.card-complete-button.complete {
    color: #ffffff;
    background-color: var(--complete-button-completed-background);
    border: 0px solid #00bb3a;
}

.card-complete-button.incomplete {
    border: var(--complete-button-border);
    color: #afafaf;
}

.card-complete-button.incomplete:hover {
    color: #6ab100;
    border-color: #00bb3a;
}

@media only screen and (max-width: 768px) {
    .card-complete-button {
        height: 22px;
        width: 22px;
        min-width: 22px;
        min-height: 22px;
    }
}