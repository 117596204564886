.add-card-container {
    background-color: var(--card-background-color);
    border: 1px solid var(--card-border-color);
    border-radius: 10px;
    box-shadow: var(--card-box-shadow);
    display: flex;
    margin: .25em 0;
    padding: .75em;
    color: #858585;
    cursor: pointer;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: opacity .2s;
    height: 40px;
    margin-bottom: 10px;
}

.add-card-container:hover {
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    border: 1px solid var(--actively-selected-border-color);
}


.add-card-text {
    height: 22px;
    line-height: 22px;
    width: 100%;
}

.add-card-icon {
    color: #858585;
    height: 1.25em;
    margin-right: .55em;
}

.new-task-input {
    border: none;
    outline: none;
    resize: none;
    margin-right: 5px;
}

.new-task-shortcut {
    border-radius: 6px;
    background-color: #474bff;
    color: #ffffff;
    padding: 3px 8px;
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
}

.add-card-container.input {
    color: var(--text-primary);
    justify-content: space-between;
}

.add-card-left {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .add-card-text {
        font-size: 16px;
    }

    .add-card-icon {
        color: #858585;
        height: 20px;
        margin-right: 8px;
    }
}