.dp-content {
    display: flex;
    flex-direction: column;
}

.dp-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dp-button {
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #d1d1d1;
    display: inline-flex;
    width: 90%;
    border-radius: 4px;
    padding: 12px;
    color: #1a1a1a;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
    cursor: pointer;
}

.dp-button-small {
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
}

.dp-button-small:hover {
    cursor: pointer;
    color: rgb(0, 17, 255);
   
}

.dp-button:hover {
    background-color: #282828;
    color: #e9e9e9;
}

.dp-button-icon {
    height: 25px;
    margin-right: 8px;
}