.timer-play-button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: #dbdbdb;
    height: 25px;
}

.timer-play-button:hover {
    color: var(--text-primary);
}

.time-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.time-sections {
    color: #999999;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
}

.ts-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    cursor: pointer;
}

.ts-content:hover {
    color: var(--text-primary);
}

.ts-title {}

.active-timer-container {
    display: flex;
    align-items: center;
}

.active-timer {
    margin-left: 10px;
    font-size: 13px;
    color: #1c80ff;
    font-weight: 500;
}