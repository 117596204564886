.card-time-estimate {
    font-size: 11px;
    background-color: var(--time-estimate-background);
    color: var(--time-estimate-color);
    padding: 0px 8px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 23px;
}

.card-time-estimate:hover {
    background-color: #767676;
    color: #ffffff;
}

.time-input-container {
    display: flex;
    align-items: center;
    column-gap: 2px;
}

.time-input-hours {
    width: 13px;
}

.time-input-minutes {
    width: 20px;
}

.time-input-colon {
    flex: 0 0 auto;
    color: #b4b4b4;
    padding: 0 2px;
}

.time-input {
    border: 0;
    outline: 0;
    width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.inline.ti-digit {
    font-size: 50px;
}

.ti-digit {
    font-size: 20px;
}

.inline.ti-sep {
    font-size: 25px;
    margin-right: 10px;
}

.ti-sep {
    margin-right: 5px;
}

.ti-digit.unsure {
    color: var(--placeholder-color);
}

.ti-digit.cursor {
    border-right: 1px solid var(--text-primary);
}

.ti-sep.unsure {
    color: var(--placeholder-color);
}

.time-input-container:focus {
    outline: none;
}

.mobile-duration-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.mobile-duration-select {
    width: 100px;
    height: 50px;
}

.mobile-duration-label {
    font-size: 19px;
    margin-left: 10px;
    margin-right: 10px;
}

@media only screen and (max-width: 768px) {
    .time-input-container {
        display: flex;
        align-items: center;
    }

    .card-time-estimate {
        font-size: 14px;
        padding: 5px 5px;
    }

    .card-time-estimate:hover {
        font-size: 14px;
        padding: 5px 5px;
    }

    .ti-digit {
        font-size: 30px;
    }

    .time-input-colon {
        flex: 0 0 auto;
        color: #b4b4b4;
        padding: 0 2px;
    }

    .ti-sep {
        margin-right: 10px;
        font-size: 23px;
    }

    .inline-dpi-content {
        font-size: 28px;
        margin-bottom: 20px;
    }
}

.inline-dpi-content {
    font-size: 50px;
}

.hour-input {
    width: 12px;
    outline: none;
    background: none;
}

.minute-input {
    width: 60px;
    outline: none;
    background: none;
}

.hour-input:focus {
    border: none;
}

.minute-input:focus {
    border: none;
}

.duration-input-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 20px;
    row-gap: 1px;
}

.duration-input-container .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    color: var(--text-secondary);
}

.colon {
    font-size: 20px;
    color: var(--text-secondary);
}