.kanban-board {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    order: 1;
    position: relative;
    background: var(--kanban-background-color);
}



.kanban-header {
    display: flex;
    align-items: top;
    overflow-x: auto;
    margin-top: 2vh;
    padding: 0px 10px;
    width: 100%;
    overflow: hidden;
    flex: 0 0 90px;
}

.kanban-body {
    display: flex;
    align-items: top;
    overflow-x: auto;
    padding: 0px 1px;
    width: 100%;
    flex: 1 1 auto;
    user-select: none;
}

.kanban-topbar {
    display: flex;
    align-items: center;
    overflow-x: auto;
    min-height: 55px;
    width: 100%;
    background-color: var(--topbar-background-color);
    padding: 0px 15px 0px 15px;
    border-bottom: var(--topbar-border-bottom);
    justify-content: space-between;
    -webkit-app-region: drag;
}

.kanban-topbar.electron {
    padding: 0px 15px 0px 85px;
}


.topbar-right {
    display: flex;
    align-items: center;
    -webkit-app-region: no-drag;
}

.topbar-left {
    display: flex;
    align-items: center;
    column-gap: 10px;
    -webkit-app-region: no-drag;
}

.calendar-month-header {
    font-weight: 600;
    color: var(--text-primary);
    font-size: 17px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 1px 5px;
    border: 1px solid transparent;
    border-radius: 7px;
}


.kanban-icon {
    font-size: 18px;
    color: #515151;
}

.dropdown-icon {
    font-size: 18px;
    color: #515151;
}

.week-navigation-buttons {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.avatar-button {
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    align-items: center;
    /* vertical-align: middle; */
    justify-content: center;
    padding: 2px;
    color: #ffffff;
    background-color: #ffa799;
    background-image:
        radial-gradient(at 75% 31%, hsla(312, 60%, 77%, 1) 0px, transparent 50%),
        radial-gradient(at 13% 77%, hsla(256, 65%, 61%, 1) 0px, transparent 50%);
}

.topbar-button {
    padding: 4px 12px;
    font-size: 13px;
    font-weight: 500;
    font-family: Avenir Next;
    cursor: pointer;
    border-radius: 5px;
    color: var(--topbar-button-secondary-color);
    background: var(--topbar-button-background-color-filled);
    box-shadow: rgba(31, 34, 37, 0.09) 0px 0px 0px 1px inset;
    font-weight: 500;
    user-select: none;
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: center;
    border: var(--topbar-button-border);
}

.topbar-toggle {
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    margin: 0;
    padding: 2px;
    color: var(--switch-option-color);
    font-size: 13px;
    line-height: 1.5714285714285714;
    list-style: none;
    display: flex;
    background-color: var(--switch-background);
    border-radius: 5px;
    transition: all .2s cubic-bezier(.645, .045, .355, 1);
    column-gap: 2px;
    -webkit-app-region: no-drag;
}

.topbar-toggle .option {
    color: var(--switch-option-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 2px 7px;
    column-gap: 4px;
}

.topbar-toggle .option:hover {
    background: var(--switch-hover-background);
    color: var(--switch-hover-color);
}

.topbar-toggle .option.active {
    color: var(--switch-option-active-color);
    background-color: var(--switch-option-active-background);
    box-shadow: var(--switch-active-box-shadow)
}

.topbar-button.filter.active {
    background: hwb(218deg 92% 0%);
    /* box-shadow: rgba(31, 34, 37, 0.09) 0px 0px 0px 1px inset; */
    color: var(--primary-color);
    border: 1px solid #0090ff;
}

.topbar-button:hover {
    color: var(--topbar-button-primary-color);
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 1px inset;
}

.topbar-actions {
    margin-right: 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.topbar-button.today {
    margin-right: 10px;
}

.topbar-button.trial-button {
    /* background: linear-gradient(to right, #FC5C7D, #6A82FB); */
    /* background: linear-gradient(to right, #FC5C7D, #6A82FB); */
    color: #ffffff;
    background: -webkit-linear-gradient(357deg, #12c2e9, #c471ed, #f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}