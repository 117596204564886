.settings {
    font-size: 15px;
    font-family: Avenir Next;
    /* font-weight: 400; */
    min-height: 500px;
    color: var(--text-primary);
}

.settings-title {
    font-weight: 500;
    font-size: 19px;
    color: var(--text-primary);
}

.settings-item {
    padding: 8px 0px;
    font-size: 16px;
    row-gap: 9px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: flex-start;
    color: var(--text-primary);

}

.settings-item-title {
    font-weight: 600;
    font-size: 12px;
    color: #8b8b8b;
    text-transform: uppercase;
    font-family: "Avenir Next";
}

.user-avatar {
    font-weight: 500;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    font-size: 25px;
    align-items: center;
    color: #ffffff;
    display: flex;
    background-color: #ffa799;
    background-image:
        radial-gradient(at 75% 31%, hsla(312, 60%, 77%, 1) 0px, transparent 50%),
        radial-gradient(at 13% 77%, hsla(256, 65%, 61%, 1) 0px, transparent 50%),
        radial-gradient(at 61% 26%, hsla(200, 91%, 63%, 1) 0px, transparent 50%);
}

.thin-button {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 500;
    color: var(--primary-color);
    cursor: pointer;
}

.thin-button:hover {
    color: black;
}


.settings-item.same-line {
    display: flex;
    align-items: center;
}

.sl {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.dl {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    row-gap: 8px;
}

.ml {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.settings-item-input {
    border: var(--settings-input-border);
    min-width: 250px;
    background: var(--settings-input-background);
    padding: 7px 13px;
    /* width: 100%; */
    border-radius: 9px;
    font-weight: 500;
    /* font-size: 16px; */
}

.settings-item-input:hover {
    border: var(--settings-input-hover-border)
}

.settings-item-input.disabled {
    background-color: var(--option-hover-background-color);
    color: #979797;
}

.log-out-button {
    /* font-size: 13px; */
    font-weight: 500;
    color: var(--primary-color);
    cursor: pointer;
    margin-top: 15px;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-left: 13px;
}

.log-out-icon {
    height: 20px;
    margin-right: 5px;
}

.log-out-button:hover {
    color: black;
}

.button {
    font-size: 13px;
    font-weight: 500;
    color: var(--button-color);
    cursor: pointer;
    background-color: var(--button-background-color);
    border-radius: 6px;
    padding: 5px 11px;
    display: inline-block;
    border: var(--button-border)
}

.button:hover {
    color: var(--button-hover-color);
    background-color: var(--button-hover-background-color);
    border: var(--button-hover-border);
}

.button.disabled {
    color: #979797;
    background-color: var(--option-hover-background-color);
    border-color: #d5d5d5;
    cursor: not-allowed;
}

.back-button {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 10px;
}

.back-button-icon {
    height: 20px;
    margin-right: 5px;
}

.back-button:hover {
    color: var(--text-primary);
}


.settings-container {
    display: flex;
    flex-direction: row;
    align-items: top;
    /* padding: 20px; */
    border-radius: 10px;
}



.settings-sidebar {
    width: 300px;
    padding: 20px 13px;
    display: flex;
    flex-direction: column;
    background-color: var(--settings-sidebar-background-color);
    border-right: var(--settings-sidebar-border-right);
    /* width: 320px; */
    /* min-width: 320px; */
    /* max-width: 320px; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-bottom: 100px;
}

.settings-sidebar-item {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--settings-sidebar-item-color);
    padding: 7px 13px;
    border-radius: 4px;
    /* margin-left: -14px; */
    width: 100%;
    margin-bottom: 4px;
}

.settings-sidebar-item:hover {
    color: var(--settings-sidebar-item-color-hover);
    background-color: var(--settings-sidebar-item-background-hover);
}

.settings-sidebar-item.active {
    color: var(--settings-sidebar-item-color-hover);
    background-color: var(--settings-sidebar-item-background-hover);
}

.settings-sidebar-icon {
    height: 18px;
    margin-right: 5px;
    color: var(--settings-sidebar-icon);
}

.settings-sidebar-item.active .settings-sidebar-icon {
    color: var(--settings-sidebar-item-color-hover);
}

.settings-content {
    width: calc(100% - 300px);
}

.settings-item-value {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.settings-rowdivider {
    margin: 12px 0;
    border-style: none;
    width: 100%;
    height: 1px;
    background-color: var(--settings-row-divider);
}

.settings-row {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
}


.row-left {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    display: flex;
    color: var(--text-primary);
}

.row-left-top {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    font-family: inherit;
    align-items: center;
    display: flex;
    margin-bottom: auto;
}

.row-left-bottom {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    font-family: inherit;
    margin-top: 2px;
}

.row-right {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    font-family: inherit;
    margin-top: 2px;
}

.settings-item-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--primary-color);
    font-weight: 500;
}

.settings-item-link-icon {
    height: 18px;
    margin-left: 5px;
}




.add-calendar-container {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--big-button-border);
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.add-calendar-container:hover {
    border: 2px solid var(--primary-color);
}

.add-calendar-text {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-primary);
    display: flex;
    align-items: center;
}

.gcal-icon {
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.disconnect-button {
    background-color: var(--destroy-button-background);
    cursor: pointer;
    padding: 11px 13px;
    color: var(--destroy-button-color);
    border: var(--destroy-button-border);
    padding: 5px 11px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
}

.add-calendar-container.added {
    background-color: var(--modal-background-color);
    border: 2px solid var(--big-button-border);
    cursor: default;
    padding: 15px 15px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.add-calendar-text.added {
    color: var(--text-primary);
    font-size: 15px;
    font-weight: 500;
}

.settings-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--settings-topbar-border-bottom);
    padding: 15px 15px 15px 20px;
    font-family: "Avenir Next";
    font-weight: 600;
    font-size: 17px;
    color: var(--text-primary)
}

.close-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--close-icon-background);
    cursor: pointer;
    margin-left: 10px;
}

.close-icon-container:hover {
    background-color: var(--close-icon-hover-background);
}

.close-icon {
    width: 15px;
    height: 15px;
}

.settings-content .content {
    padding: 15px 30px;
    font-family: Inter;
    margin-bottom: 50px;
}

.settings-sidebar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-bottom: 10px; */
    font-family: "Avenir Next";
    font-weight: 600;
    font-size: 17px;
    color: black;
    padding-left: 13px;
    margin-bottom: 4px;
}

.settings-sidebar-header-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Avenir Next";
    font-weight: 600;
    font-size: 17px;
    color: black;
}

.settings-sidebar-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.settings-sidebar-avatar-initials {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    /* mesh gradient background */
    background-color: #ffa799;
    background-image:
        radial-gradient(at 75% 31%, hsla(312, 60%, 77%, 1) 0px, transparent 50%),
        radial-gradient(at 13% 77%, hsla(256, 65%, 61%, 1) 0px, transparent 50%),
        radial-gradient(at 61% 26%, hsla(200, 91%, 63%, 1) 0px, transparent 50%);
}


.settings-sidebar-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.settings-sidebar-header-right-name {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-primary);
    line-height: 19px;
}

.settings-sidebar-header-right-email {
    font-size: 14px;
    font-weight: 500;
    color: #9b9b9b;
    line-height: 15px;
}

.settings-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-bottom: 20px; */
    padding: 12px 0px;
}

.settings-section-header {
    /* margin-left: 13px; */
    /* margin-bottom: 7px; */
    text-transform: uppercase;
    font-family: Avenir Next;
    font-weight: 600;
    color: #4c4c4c;
    font-size: 12px;
    padding: 8px 13px 7px 14px;

}

.settings-section-divider {
    width: calc(100% - 18px);
    height: 1px;
    background-color: var(--settings-row-divider);
    margin-left: auto;

    /* margin-right: 12px; */
    margin-right: 5px;
}

.same-line {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.email-forwarding-email {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 80%;
}

.email-forwarding {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
}

.email-forwarding-email .button {
    /* flex: 1; */
    /* width: 118px; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 126px;
}

.email-forwarding .disclaimer {
    font-size: 12px;
    font-weight: 500;
    color: #9b9b9b;
    line-height: 15px;
    margin-top: 10px;
}

.download-containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    padding: 10px;
}

.download-app-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 18px;
    padding: 30px;
    background: var(--download-container-background);
    border-radius: 30px;
    overflow: hidden;
    border: 1px solid var(--download-container-background);
}

.download-app-container .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 5px;
}

/* this will contain an image, make it take the full height of the container */
.download-app-container .right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.download-app-container .right.mac {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}


.download-app-container .right.mac img {
    width: 100%;
    height: 100%;
    margin-left: 70px;
    transform: translate(4%, 4%) scale(1.45);
}

.download-app-container .right img {
    width: 100%;
    height: 100%;
    margin-left: 70px;
    /* little rotation */
    transform: rotate(-15deg);
}

.download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    padding: 10px 14px;
    background: var(--download-button-background);
    border-radius: 9px;
    font-weight: 500;
    cursor: pointer;
}

.download-button:hover {
    background: var(--download-button-background-hover);
}

.download-app-buttons {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    row-gap: 16px;
}

.download-button-icon {
    width: 20px;
    height: 20px;
    color: #868686;
}

.download-app-container .title {
    font-size: 20px;
    font-weight: 500;
}

.calendar-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    row-gap: 10px;
    background: var(--big-button-background);
    border-radius: 10px;
    padding: 8px 14px;
    border: 1px solid var(--big-button-border-color);
    cursor: pointer;
    column-gap: 25px;
}

.calendar-dropdown:hover {
    background: var(--big-button-background-hover);
}

.calendar-dropdown .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    row-gap: 5px;
    column-gap: 5px;
}

.default-calendar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 10px;
    column-gap: 5px;
}

.settings .meta {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
}

.settings .meta .title {
    font-size: 18px;
    font-weight: 500;
    color: var(--text-primary);
}

.settings .meta .subtitle {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-secondary);
}

.calendar-list-item .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
}

.settings .calendar-settings-content .calendar-list-item {
    justify-content: space-between;
}

.integration-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    max-width: 400px;
}

.integration-option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 14px;
    cursor: pointer;
    row-gap: 2px;
    background: var(--download-container-background);
    border-radius: 10px;
    border: 1px solid var(--big-button-border-color);
}

.integration-option:hover {
    background: var(--download-button-background-hover);
    border: 1px solid var(--primary-color);
}

.integration-option.selected {
    border: 2px solid var(--primary-color);
}

.integration-option .meta {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 5px;
    margin-left: 5px;
}

.integration-option svg {
    min-width: 12px;
    min-height: 12px;
    margin-top: 2px;
}

.integration-option .meta .title {
    font-size: 13px;
    font-weight: 400;
    color: var(--text-primary);
}

.image-cropper {
    padding: 20px;
}

.image-cropper-header {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--text-primary);
}

.image-cropper-container {
    position: relative;
    height: 300px;
}

.image-cropper-buttons {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: end;
}

/* Mobile */
@media (max-width: 768px) {
    .settings-container {
        flex-direction: column;
    }

    .settings-content {
        width: 100%;
    }

    .mobile-settings-page {
        padding: 0px;
    }

    .ant-drawer .ant-drawer-body {
        padding: 0px;
    }

    .settings-sidebar {
        width: 300px;
        padding: 20px 13px;
        display: flex;
        flex-direction: column;
        background-color: var(--settings-sidebar-background-color);
        border-right: var(--settings-sidebar-border-right);
        /* width: 320px; */
        /* min-width: 320px; */
        /* max-width: 320px; */
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
}