.upgrade-container {
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    opacity: 1;
    overflow-y: scroll;
    pointer-events: unset;
    width: 100%;
    border-radius: 5px;
    font-family: Inter;
}

.top-half {
    background: var(--modal-background-color);
    border-bottom-color: var(--border-color);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-radius: 16px 16px 0 0;
    box-sizing: border-box;
    flex: 999 1 auto;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.top-half.no-border {
    border-bottom: none;
}

.bottom-half {
    background: var(--time-estimate-background);
    box-sizing: border-box;
    flex: 999 1 auto;
    padding: 30px 60px 40px 60px;
}


.upgrade-h1 {
    box-sizing: border-box;
    color: var(--text-primary);
    font-family: P22 Mackinac Pro;
    font-size: 31px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.2;
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
}

.upgrade-h2 {
    box-sizing: border-box;
    color: #585858;
    font-family: P22 Mackinac Pro;
    font-size: 18px;
    font-weight: 500;
    height: 14px;
    letter-spacing: 0;
    line-height: 1.2;
    margin: 0;
    text-align: center;
    margin-bottom: 29px;
}

.upgrade-h3 {
    box-sizing: border-box;
    color: #738696;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 13.5px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.35;
    margin: 0;
    text-align: center;

    margin-bottom: 10px;
}

.upgrade-modal-button {
    align-items: center;
    background: #f16f3b;
    border: 1px solid;
    border-color: #f16f3b;
    border-radius: 20px;
    box-shadow: 0 8px 30px -10px #9b9b9b;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 15px;
    font-weight: 600;
    height: 39px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 1.3;
    opacity: 1;
    outline: 0;
    overflow: hidden;
    padding: 0 16px;
    position: relative;
    transition: all .2s ease 0s;
    -webkit-user-select: none;
    user-select: none;
    z-index: 1;
    margin-bottom: 15px;
}

.upgrade-modal-button:hover {
    background: var(--text-primary);
    border-color: var(--text-primary);
}

.upgrade-toggle {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    margin-bottom: 10px;
}

.pro-feature {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.coming-soon-chip {
    font-size: 12px;
    background-color: #e1e1e1;
    color: var(--text-primary);
    border-radius: 25px;
    padding: 3px 9px;
    font-weight: 500;
}

.pro-feature-icon {
    height: 36px;
    margin-right: 14px;
    padding: 9px;
    border-radius: 50%;
    background-color: #ffe9dfd4;
    color: #292929;
}

.pro-feature-text {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 100%;
    margin-right: 7px;
    font-weight: 400;
    color: var(--text-primary);
    font-size: 14px;
}

.upgrade-modal-button-icon {
    margin-right: 10px;
    height: 15px;
    color: #ffffff;
}

.close-upgrade-modal-button {
    height: 25px;
    background-color: var(--close-icon-background);
    border-radius: 50%;
    padding: 4px;
    /* display: block; */
    /* width: 100%; */
    float: right;
    cursor: pointer;
}

.close-upgrade-modal-button:hover {
    color: #ffffff;
    background-color: var(--close-icon-hover-background);
}

.close-button-container {
    position: sticky;
    width: 100%;
    top: 0px;
    left: 0px;
    flex: 0 0 0px;
    z-index: 3;
    pointer-events: auto;
    opacity: 1;
}

.pro-color {
    color: #f16f3b;
}

.bottom-half-text {
    margin-bottom: 30px;
    font-weight: 500;
    color: #3f3f3f;
}

.purplePlanDiv {
    width: 400px;
    height: 150px;
    background: #f5f4ff;
    border-radius: 5px;
    padding: 20px 25px;
    margin-top: 25px;
}

.smallTitle {
    font-weight: bold;
    font-size: 12px;
    color: #8c8d8e;
    padding-top: 0;
    text-align: left;
}

.purpleText {
    font-weight: bold;
    font-size: 13px;
    color: #817eff;
    margin-top: 5px;
    padding-top: 0;
    text-align: left;
}

.success-image {
    width: 100%;

}

.success-h1 {
    font-size: 26px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: 500;
}

.success-h2 {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    width: 350px;
}


.pro-feature-container {
    display: block;
    padding: 20px;
    background: var(--pro-feature-background);
    border: 1px solid;
    border-color: var(--pro-feature-border);
    border-radius: 10px;
    box-shadow: none;
    box-sizing: border-box;
    min-width: 0;
    padding: 20px 25px;
    position: relative;
    width: auto;
    max-width: 600px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    margin-bottom: 10px;
}

.pro-feature-text {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 100%;
    margin-right: 7px;
}

.pro-feature-h1 {
    box-sizing: border-box;
    color: var(--text-primary);
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.2;
    margin: 0;
    text-align: center;
    margin-bottom: 4px;
    font-weight: 600;
    font-family: 'Avenir Next';
}

.pro-feature-h2 {
    box-sizing: border-box;
    color: #4f5d69;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14.5px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.35;
    margin: 0;
    padding-bottom: .5rem;
}

.pro-upgrade-button {
    align-items: center;
    background: var(--primary-color);
    border: 1px solid;
    border-color: var(--primary-color);
    border-radius: 20px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 15px;
    font-weight: 600;
    height: 39px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 1.3;
    opacity: 1;
    outline: 0;
    overflow: hidden;
    padding: 0 16px;
    position: relative;
    transition: all .2s ease 0s;
    -webkit-user-select: none;
    user-select: none;
    z-index: 1;
    margin-top: auto;
    margin-bottom: auto;
}

.pro-upgrade-button-icon {
    margin-right: 7px;
    height: 20px;
    color: #ffffff;
}

.pro-upgrade-button:hover {
    background-color: var(--button-hover-color);
    border-color: var(--button-hover-color);
}

.pricing-options-container {
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.pricing-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f7f7;
    border: 1px solid var(--border-color);
    padding: 16px 30px;
    border-radius: 11px;
    cursor: pointer;
    flex: 1 1;
    width: 175px;
    font-family: Avenir Next;
}

.pricing-option-price {
    font-size: 24px;
    font-weight: 600;
}

.pricing-option-text {
    font-size: 14px;
    font-weight: 500;
}

.pricing-option.selected {
    background-color: #ffd60dd6;
    color: #323232;
    /* box-shadow: 0 8px 30px -10px #000000; */
    border: 1px solid transparent;
}

.pricing-option:hover {
    background-color: var(--option-hover-background-color);
    color: var(--text-primary);
}

.pricing-option.selected:hover {
    background-color: #ffd60dd6;
    color: #323232;
    /* box-shadow: 0 8px 30px -10px #000000; */
    border: 1px solid transparent;
}


.pricing-h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    width: 350px;
}

@media only screen and (max-width: 768px) {
    .pricing-options-container {
        box-sizing: border-box;
        margin-bottom: 40px;
        margin-top: 10px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        width: 80%;
    }

    .pricing-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f7f7f7;
        border: 1px solid var(--border-color);
        padding: 16px 30px;
        border-radius: 11px;
        cursor: pointer;
        flex: 1 1;
        width: 100%;
        font-family: Avenir Next;
    }

    .pricing-option.selected {
        background-color: #ffd60dd6;
        color: #323232;
        /* box-shadow: 0 8px 30px -10px #000000; */
        border: 1px solid transparent;
    }

}

.upgrade-logo {
    height: 40px;
    margin-right: 10px;
}

.feature-line {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-bottom: 2px;
}

.feature-line .checkmark {
    height: 15px;
    width: 15px;
    color: #00a900;
    margin-right: 7px;
}

.features-card {
    padding: 9px 15px;
    border: 1px solid var(--border-color);
    background-color: var(--option-hover-background-color);
    border-radius: 10px;
    margin-top: 10px;
    min-width: 350px;
}

.success-h3 {
    max-width: 320px;
    margin-top: 14px;
    text-align: center;
    font-size: 13px;
    margin-bottom: 5px;
}


.success-h2 {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    width: 350px;
    font-weight: 500;
}

.success-image.small {
    width: 250px;
    border-radius: 10px;
}

.success-h1.small {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 40px;
}

.success-text {
    background: -webkit-linear-gradient(357deg, #12c2e9, #c471ed, #f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 40px;
}

.pricing-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin-top: 20px; */
    padding: 0px 60px 30px 60px;
}

.pricing-table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding: 15px 0px;
    /* font-weight: 300; */
}

.pricing-table-row.upgrade-header {
    border-bottom: none;
}

.pricing-table-row .pricing-table-cell {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
}

.pro-feature-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100%;
    column-gap: 8px;
    min-width: 250px;
}


.pricing-table-cell .price {
    color: var(--text-primary);
    font-size: 20px;
    letter-spacing: 0;
    line-height: 1.4;
    margin: 0;
    font-weight: 600;
}

.plan-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #640ef5;
    padding: 6px 15px;
    background-color: #f1efff;
    border-radius: 25px;
    column-gap: 4px;
    font-weight: 600;
    /* cursor: pointer; */
}

.plan-button .check {
    height: 20px;
    width: 20px;
}

.upgrade-plan-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--primary-button-color);
    padding: 6px 25px;
    background-color: var(--primary-color);
    border-radius: 25px;
    column-gap: 7px;
    font-weight: 600;
    cursor: pointer;
}

.upgrade-plan-button:hover {
    background-color: var(--button-hover-color);
}

.pricing-table-cell.upgrade-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    row-gap: 15px;
}

.pro-feature-cell .feature-icon {
    color: #00a900;
    height: 16px;
    width: 16px;
}

.billing-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    column-gap: 10px;
    min-height: 20px;
}

.pricing-table-cell.upgrade-header .name {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-bottom: -11px;
}

.upgrade-modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
    width: 100%;
    padding: 20px 20px 0px 20px;
    background: var(--modal-gradient);
}

.pricing-table-cell .check {
    height: 20px;
    width: 20px;
    color: var(--check-color);
}

.plan-button .check {
    height: 20px;
    width: 20px;
    color: var(--primary-color);
}

.save-chip {
    background: rgb(220 252 231);
    color: rgb(22 101 52);
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 16px;
    font-weight: 600;
}

.student-card {
    width: calc(100% - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--message-background);
    padding: 20px;
    border-radius: 8px;
    font-size: 17px;
    font-weight: 500;
    color: var(--text-primary);
}

/* Mobile */
@media only screen and (max-width: 600px) {

    .success-h1 {
        font-size: 18px;
        margin-bottom: 0px;
        margin-top: 0px;
        text-align: left;
        width: 100%;
    }

    .success-h2 {
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 0px;
        text-align: left;
        width: 90%;
        font-weight: 400;
    }

    .message-modal {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

}