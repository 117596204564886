.card-modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px 35px 5px 35px;
  /* border-bottom: 1px solid #eaeaea; */
}

.create-task-modal {
  font-family: Inter;
  overflow: hidden;
  border-radius: 10px;
}

.editing {
  margin-bottom: 0px;
}

.cm-same-line {
  display: flex;
  align-items: baseline;
}

.cmh-more-menu {
  cursor: pointer;
  height: 30px;
  color: #9f9f9f;
  margin-top: 5px;
}

.cmh-more-menu:hover {
  color: var(--text-primary);
}

.mcf-button {
  user-select: none;
  transition: background 20ms ease-in 0s;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 3px;
  min-height: 34px;
  padding: 6px 8px 7px;
  font-size: 14px;
  overflow: hidden;
  color: var(--modal-property-value-color);
}

.mcf-button.empty {
  color: var(--modal-property-value-empty-color);
}

.mcf-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mcf-header .title {
  font-weight: 600;
  color: #595959;
  font-size: 21px;
}

.mcf-header .close-icon {
  height: 20px;
  width: 20px;
  color: #b0b0b0;
  cursor: pointer;
}

.mcf-header .close-icon:hover {
  color: var(--text-primary);
}

.mcf-button:hover {
  background: var(--modal-property-hover-color);
}

.mcf-button.create {
  background: #000000;
  border: 1px solid #e7e7e7;
  box-shadow: -1px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 7px;
  color: #ffffff;
  margin-right: 0px;
  padding: 8px 15px;
}

.modal-card-body-notes-header {
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--modal-section-header);
  font-size: 14px;
}

.modal-card-body-notes {
  /* margin-left: 25px; */
  padding-top: 18px;
  padding-left: 3px;
  padding-bottom: 18px;
  border-bottom: 1px solid var(--modal-section-divider);
}

.modal-card-body-calendar-events {
  /* margin-left: 25px; */
  padding-top: 18px;
  padding-left: 3px;
  padding-bottom: 18px;
  border-bottom: 1px solid var(--modal-section-divider);
}

.modal-card-body-calendar-events .calendar-event {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  column-gap: 9px;
  cursor: pointer;
  color: var(--modal-property-value-color);
  font-weight: 400;
}

.modal-card-body-calendar-events .calendar-event:hover {
  color: var(--text-secondary);
}

.calendar-event .icon {
  height: 18px;
  width: 18px;
  margin-top: 3px;
}

.calendar-event .left-stick {
  width: 4px;
  background: black;
  border-radius: 14px;
  opacity: 0.75;
}

.calendar-event .meta {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.calendar-event .meta .property {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 6px;
}

.meta .property .icon {
  height: 18px;
  width: 18px;
  margin-top: 0px;
}

.modal-card-body-notes.subtasks {
  border-bottom: none;
}

.create:hover {
  background: blue;
  border: 1px solid #000000;
  color: #ffffff;
}

.mcf-button .icon {
  margin-right: 5px;
  height: 18px;
  width: 18px;
}

.modal-card-footer .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.modal-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 3px;
  width: 100%;
  justify-content: lef;
}

.modal-card-footer.editing {
  /* margin-top: 7px; */
  margin-bottom: 3px;
  /* border-top: 1px solid #e7e7e7; */
  /* padding-top: 13px; */
  padding-top: 0px;
  margin-top: 0px;
}

.mcf-description {
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
  border-style: none;
  box-shadow: none;
  color: var(--text-primary);
  display: block;
  line-height: 20px;
  font-family: "Open Sans";
  outline: none;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  transition-duration: 85ms;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease;
  width: 100%;
  margin-right: 15px;
  border: 2px solid transparent;
  /* background-color: #f8f8f8; */
  padding: 12px;
  border-radius: 7px;
  border: 1px solid #e0e0e0;
}

.subtasks-section-modal {
  padding-left: 29px;
}

.mcf-footer {
  display: flex;
  justify-content: end;
  align-items: center;
}

.mcf-footer .cancel-button {
  margin-right: 10px;
  color: #646464;
  font-weight: 500;
  cursor: pointer;
}

.cancel-button:hover {
  color: var(--text-primary);
}

.card-modal-description {
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
  border-style: none;
  box-shadow: none;
  color: var(--text-primary);
  display: block;
  line-height: 29px;
  font-family: "Open Sans";
  outline: none;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  transition-duration: 85ms;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease;
  width: 100%;
  /* background-color: #f8f8f8; */
  /* padding: 12px; */
  border-radius: 7px;
  /* border: 1px solid #e0e0e0; */
  margin-left: 3px;
  font-weight: 600;
  font-size: 23px;
  padding: 7px 10px;
  background: transparent;
}

.card-modal-description.create-textarea {
  margin-left: -6px;
}

.card-modal-description:focus {
  background-color: var(--modal-textarea-hover-color);
}

.card-modal-description:hover {
  background-color: var(--modal-textarea-hover-color);
}

.card-modal-notes {
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
  border-style: none;
  box-shadow: none;
  color: var(--text-primary);
  display: block;
  line-height: 22px;
  font-family: "Inter";
  outline: none;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  transition-duration: 85ms;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease;
  width: calc(100% - 23px);
  /* background-color: #f8f8f8; */
  /* padding: 12px; */
  border-radius: 7px;
  /* border: 1px solid #e0e0e0; */
  font-weight: 400;
  font-size: 14px;
  background: transparent;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #b0b0b0;
}

.modal-header .right {
  cursor: pointer;
}

.modal-header .left {
  cursor: pointer;
}

.close-icon-container-2 svg {
  height: 20px;
  width: 20px;
}

.more-icon-container {
  margin-top: 13px;
  cursor: pointer;
  color: #b0b0b0;
}

.more-icon-container svg {
  height: 20px;
  width: 20px;
}

.amazing-modal .ant-modal-body {
  padding: 0px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.amazing-modal .ant-modal-content {
  position: relative;
  background-clip: padding-box;
  border: 0;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
  border-radius: 10px;
  background-color: var(--modal-background-color);
  padding: 0px;
}

.card-modal-body {
  padding: 0px 35px 30px 35px;
}

.modal-property {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.modal-card-properties {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--modal-section-divider);
  /* border-top: 1px solid #eaeaea; */
  /* padding-top: 14px; */
}

.modal-property .title {
  color: var(--modal-property-title-color);
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 160px;
  font-weight: 500;
}

.modal-property .value {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.value .timer-play-button {
  width: auto;
  height: auto;
  font-size: 13px;
}

.value .timer-play-button:hover {
  width: auto;
  height: auto;
}

.modal-property .title .icon {
  width: 18px;
  height: 18px;
  display: block;
  color: var(--modal-property-title-icon);
  flex-shrink: 0;
  backface-visibility: hidden;
}

.auto-link {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
}

.placeholder-text {
  color: gray;
}

/* Mobile CSS using media query */

@media (max-width: 768px) {
  .modal-property .title {
    width: 120px;
    flex: 0.5 1 120px;
    align-items: start;
  }

  .modal-property .value {
    flex: 1 1 100px;
    align-items: start;
    flex-direction: column;
    padding: 2px;
  }

  .mcf-button {
    padding: 3px;
  }

  .timer-play-button {
    padding: 3px;
  }
}
