.floating-timer {
    position: absolute;
    right: 50px;
    bottom: 20px;
    background: var(--timer-background);
    color: var(--timer-primary-color);
    padding: 10px 22px;
    border-radius: 22px;
    display: flex;
    align-items: initial;
    flex-direction: column;
    row-gap: 5px;
    min-width: 150px;
    z-index: 1000;
    -webkit-app-region: no-drag;
}

.floating-timer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    column-gap: 10px;
}

.floating-timer-header .actions {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.floating-timer-header .icon {
    height: 15px;
    width: 15px;
    cursor: pointer;
    color: var(--text-secondary);
}

.floating-timer-header .icon:hover {
    color: var(--timer-primary-color);
}

.floating-timer .task-name {
    font-size: 14px;
    font-weight: 600;
    color: var(--text-secondary);
    max-width: 200px;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.floating-timer .task-name:hover {
    color: var(--timer-primary-color);
}

.floating-timer .timer {
    display: flex;
    align-items: initial;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
    overflow: hidden;
}

.timer-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0px;
    /* width: 100%; */
    font-size: 8px;
}

.floating-timer-header .time {
    font-size: 20px;
}

.timer .time {
    font-size: 30px;
}

.timer-controls {
    display: flex;
    align-items: center;
    justify-content: initial;
    column-gap: 10px;
}

.timer-control {
    cursor: pointer;
    border-radius: 4px;
    padding: 2px 3px;
}

.timer-control .icon {
    height: 20px;

}

.timer-control:hover {
    background: var(--timer-control-hover-background);
    color: var(--timer-control-hover-color);
}