.sidebar-container {
    /* padding: 1em; */
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--kanban-background-color);
    /* box-shadow: 0px 1px 3px 0px rgb(18 18 18 / 25%); */
    border-right: var(--sidebar-border);
}

.sidebar-container.today-mode {
    border-right: none;
    -webkit-app-region: no-drag;
}

.sidebar-toggle {
    position: absolute;
    z-index: 1000;
    height: 100vh;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #d1d1d1;
}

.sidebar-toggle:hover {
    color: var(--text-primary);
}

.sidebar-toggle-icon {
    height: 30px;
}

.sidebar {
    z-index: 500;
    order: 0;
}

.sidebar-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5em;
}


.sidebar-search-container {
    display: flex;
    align-items: center;
    background-color: var(--button-hover-background-color);
    border-radius: 6px;
    /* border: 1px solid #c3c3c3; */
    padding: .5em .5em;
    font-size: 14px;
    margin-bottom: 1em;
}

.sidebar-search-icon {
    margin-right: 5px;
    height: 20px;
    color: #818181;
}

.sidebar-search-input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
}

.sidebar-account-container {}

.braindump-column {
    background-color: #f7f7f7;
    height: 96vh;
    border-radius: 6px;
    border: 2px solid #323232;
    color: var(--text-primary);
    margin: 10px 0px 10px 10px;
    box-shadow: 7px 0 8px -2px #f1f1f1;
    box-shadow: 0px 2px 4px rgba(109, 109, 109, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.braindump-container {
    margin: 15px 15px;
    display: flex;
    flex-direction: column;
    flex: 0 0 265px;
    height: 92vh;
}

.braindump-header {
    margin-bottom: .25em;
    padding: 10px 10px 0;
}

.braindump-header-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: .5em;
    color: var(--text-primary);
}

.draggable-container.braindump {
    min-height: 400px;
    overflow-y: auto;
    flex: 1 1 auto;
    padding: 0px 10px 10px 10px;
}

/* Mobile */
@media (max-width: 768px) {
    .draggable-container.braindump {
        padding: 0px 10px;
        padding-bottom: 80px;
    }

    .mini-calendar-header {
        flex: 0.20 1 25px;
    }

    .calendar-settings-bar {
        flex: 0.05 0 auto;
    }
}

.braindump-header-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collapse-icon {
    height: 20px;
    width: 20px;
    color: var(--topbar-button-third-color);
    cursor: pointer;
}

.braindump-topbar {
    display: flex;
    align-items: center;
    overflow-x: auto;
    min-height: 55px;
    width: 100%;
    background-color: var(--topbar-background-color);
    /* margin-bottom: 15px; */
    padding: 0px 15px;
    border-bottom: var(--topbar-border-bottom);
    /* flex: 1 1 54px; */
    -webkit-user-select: none;
    -webkit-app-region: drag;
}

.braindump-topbar.disabled {
    -webkit-app-region: no-drag;
}

.sidebar-logo {
    height: 50px;
    width: 50px;
}

.sidebar-logo.electron {
    margin-left: 65px;
    visibility: hidden;
}

.collapse-icon:hover {
    color: var(--topbar-button-primary-color);
}

@media only screen and (max-width: 768px) {
    .braindump-header-title {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 10px;
    }

}

.hover-parent:hover .child {
    visibility: visible;
  }

  .hover-parent .child {
    visibility: hidden;
  }