@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div:focus-visible {
  outline: none;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-switch-checked {
  background-color: var(--primary-color) !important;
}

/* Let's add the Avenir font face from ./fonts/Avenir-Next ttf files */
@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Medium.ttf') format('truetype');
  font-weight: 300, 400, 500, 600, 700, 800, 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-UltraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/*

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
} 

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-Heavy.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}



@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next/AvenirNext-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
} */