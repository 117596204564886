.auth-page {
    height: 100vh;
    width: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
    color: var(--text-primary);
    -webkit-user-select: none;
    -webkit-app-region: drag;
}

.auth-left,
.auth-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-right {
    height: 100%;
    vertical-align: top;
    background-image: var(--auth-background-image);
    background-color: #E7E7E7;
    background-size: cover;
    overflow: hidden;
    background-position: top;
}

.auth-left-container {
    max-width: 400px;
    -webkit-app-region: no-drag;
}

.auth-card {
    background-color: #eee;
    padding: 20px;
    width: 250px;
    height: 70px;

    padding: 0.75em;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #EFEFEF;
    box-shadow: 0 1px 1px #EFEFEF;
    font-size: .85em;
    transition: opacity .2s;

}

.auth-input {
    box-sizing: border-box;
    margin: 0px 0px 24px;
    min-width: 0px;
    display: block;
    width: 100%;
    appearance: none;
    border-width: 1px;
    border-style: solid;
    border-image: initial;

    height: 46px;
    line-height: 46px;
    padding: 0px 16px;
    outline: none;
    border-radius: 8px;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    pointer-events: all;
    font-size: 14px;
    background: var(--search-bar-background);
    border-color: var(--border-color);
}

.auth-input:focus {
    border-color: var(--text-primary);
    box-shadow: rgba(19, 19, 20, 0.1) 0px 0px 0px 4px;
}

.auth-button {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    -webkit-appearance: none;
    appearance: none;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border: 0px;
    color: #ffffff;
    background-color: var(--primary-color);
    cursor: pointer;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 500;
    outline: none;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    padding: 0px 0px;
    pointer-events: all;
    position: unset;
    will-change: transform;
    height: 45px;
    font-size: 14px;
    line-height: 40px;
    border-radius: 8px;
    width: 100%;
}

.auth-button:hover {
    background-color: var(--button-hover-background-color);
    color: var(--button-hover-color);
}

.auth-logo {
    height: 50px;
    margin-bottom: 30px;
    border: 1px solid #2a2a2a;
    border-radius: 12px;
}

.auth-button-thin {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 500;
    margin-top: 15px;
    border: none;
    padding: 0px;
    font-size: 15px;
    /* width: 100%; */
}

.auth-button-thin:hover {
    color: var(--text-primary);
}

.or-spacer {
    margin: 5px 0px;
    font-weight: 500;
}

.auth-meta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


@media only screen and (max-width: 768px) {

    .auth-input {
        font-size: 16px;
    }

    h1 {
        font-size: 20px;
    }

    .auth-page {
        flex-direction: column;
    }

    .auth-left,
    .auth-right {
        width: 100%;
    }

    .auth-left {
        padding: 20px;
    }
}

.header {
    margin-top: 0;
    color: var(--text-primary);
    font-weight: 500;
    font-size: 27px;
    font-family: P22 Mackinac Pro;
}

.multi-select-options {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.multi-select-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #EFEFEF;
    cursor: pointer;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    font-family: Avenir Next;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.multi-select-icon {
    margin-right: 10px;
    font-size: 20px;
}

.option-description {
    display: flex;
    align-items: center;
}

.multi-select-option.active {
    border: 2px solid var(--primary-color);
}

.option-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1.5px solid #888888;
}

.option-circle.active {
    border: 2px solid var(--primary-color);
    background-color: var(--primary-color);
    color: white;
}

.empty-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.upload-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 30px;
    color: var(--primary-color);
}

.upload-avatar-text {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}

.upload-avatar:hover {
    color: var(--text-primary);
}

.description {
    font-size: 14px;
    margin-bottom: 20px;
}