.shortcuts-container {
    background-color: #171918;
    border-radius: 5px;
    color: #ffffff;

    padding: 20px;
}

.shortcuts-header {
    color: #dddddd;
    margin-bottom: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-title {
    font-size: 21px;
    color: #dddddd;
    font-weight: 500;
}

.shortcuts-close-icon {
    height: 20px;
    color: #dddddd;
    cursor: pointer;
}

.shortcuts-body {
    color: #0f0f0f;
}


.shortcuts-section {
    margin-bottom: 20px;
}

.shortcuts-section-header {
    font-size: 17px;
    color: #d1d1d1;
    font-weight: 500;
}

.shortcuts-section-description {
    font-size: 14px;
    color: #d1d1d1;
    max-width: 90%;
    margin-bottom: 11px;
}

.shortcuts-section-body {
    font-size: 15px;
    color: #0f0f0f;
}

.shortcut {
    display: flex;
    align-items: center;
    padding: 10px 5px;
}

.shortcut-title {
    font-weight: 500;
    color: #cdcdcd;
}

.shortcut-keys {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.shortcut-key {
    border-radius: 5px;
    background-color: #3a3a3a;
    border: 1px solid #636363;
    color: #e5e5e5;
    padding: 4px 14px 4px 14px;
    font-size: 13px;
    font-weight: 500;
}