.duration-picker-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dp-title {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
}