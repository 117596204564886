.focusmode-bg {
    position: fixed;
    z-index: -99;
    width: 100%;
    height: 100%
}

.video-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: -99;
}

video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
    object-fit: cover;
}

.focusmode-mobile {
    z-index: 10000;
    height: 100vh;
    background-color: var(--text-primary);
    position: absolute;
    overflow-x: hidden;
    width: 100%;
}

@media (min-aspect-ratio: 16/9) {
    .video-container video {
        height: 56.25vw;
        pointer-events: none;
    }
}

@media (max-aspect-ratio: 16/9) {
    .video-container video {
        width: 177.78vh;
        pointer-events: none;
    }
}

.fm-content {
    width: 100%;
    background-color: var(--text-primary)73;
    height: 100vh;
    color: #ffffff;
    font-weight: 500;
    /* font-size: 46px; */
    text-align: center;
    display: flex;
    align-items: initial;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px;
}

.fm-task-card {
    padding: 15px 15px 20px 16px;
    background-color: #ffffff;
    color: var(--text-primary);
    border-radius: 6px;
    display: inline-flex;
    align-items: initial;

    margin-bottom: 20px;
}

.fm-timer {
    font-size: 80px;
    line-height: 80px;
}

.fm-footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
}

.fm-buttons {
    display: flex;
    align-items: center;
}

.pause {
    margin-right: 3px;
}

.fm-button {
    color: #ffffff;
    border-radius: 50%;
    background-color: transparent;
    font-size: 20px;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.fm-button .icon {
    height: 60px;
}

.fm-background-picker {
    /* padding: 16px 28px; */
    background-color: #ffffff;
    border-radius: 50%;
    height: 50px;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    width: 50px;
    margin-right: 10px;
    border: 2px solid #ffffff;
    cursor: pointer;
}

.bg-option {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    background-color: var(--button-hover-background-color);
    padding: 14px 11px;
    border-radius: 7px;
    margin-bottom: 13px;
    width: 100%;
    cursor: pointer;
}

.bg-option:hover {
    background-color: #dbdbdb;
    color: var(--text-primary);
}

.bg-option img {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    margin-right: 10px;
}

.bg-options {
    padding: 0px 15px;
}

.fm-task-info {
    display: flex;
    flex-direction: column;
}


@media only screen and (max-width: 768px) {
    .fm-content {
        width: 100%;
        background-color: var(--text-primary)b5;
        height: 100vh;
        color: #ffffff;
        font-weight: 500;
        /* font-size: 46px; */
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 20px;
    }

    .fm-timer {
        font-size: 80px;
        margin-top: 50px;
    }

    .fm-button {
        padding: 11px 20px;
        color: var(--text-primary);
        border-radius: 14px;
        background-color: #ffffff;
        font-size: 20px;
        align-items: center;
        display: flex;
        height: 55px;
        cursor: pointer;
    }

    .fm-button .icon {
        height: 25px;
        margin-right: 5px;
    }

    .fm-background-picker {
        /* padding: 16px 28px; */
        background-color: #ffffff;
        border-radius: 10px;
        height: 55px;
        color: var(--text-primary);
        display: flex;
        align-items: center;
        width: 55px;
        margin-right: 10px;
        border: 1px solid #ffffff;
    }

    .pause {
        margin-right: 10px;
    }
}

.card-description.fm {
    font-size: 20px;
    line-height: 23px;
}

.complete-container {
    margin-top: 5px;
    margin-right: 5px;
}

.fmp-button {
    display: flex;
    align-items: center;
    border: 1px solid var(--text-primary);
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #ffffff;
    color: #272727;
}

.fmp-button:hover {
    display: flex;
    align-items: center;
    border: 1px solid #001742;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
}

.fmp-icon {
    height: 15px;
    width: 15px;
    color: gray;
}

.fmp-content {
    flex: 1;
    margin-right: 20px;
}

.fmp-title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 3px;
}

.fmp-description {
    font-size: 14px;
}

.dp-description {
    font-size: 14px;
    padding: 0px 10px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #272727;

}