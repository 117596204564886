.event-modal {
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 375px;
    white-space: normal;
    overflow-y: auto;
    max-height: 70vh;
    row-gap: 20px;
    user-select: text;
}

.event-modal .topbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    margin-bottom: 10px;
}

.event-modal .properties {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.event-modal .header {}

.topbar .icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
    color: var(--button-color);
}

.topbar .icon:hover {
    color: var(--button-hover-color);
}

.event-modal .title {
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: 'Inter', sans-serif;
}

.event-modal .subtitle {
    color: var(--text-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    font-family: 'Inter', sans-serif;
}

.event-modal .attendee {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
}

.event-modal .attendee .avatar {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: var(--primary-color);
}

.event-modal .attendee .avatar-container {
    display: flex;
    align-items: center;
    position: relative;
}

.avatar-container .status-container {
    display: flex;
    align-items: center;
    column-gap: 5px;
    position: absolute;
    bottom: -3px;
    right: -5px;
    z-index: 10;
    background: black;
    border-radius: 50%;
    color: white;
    padding: 1px;
    border: 1px solid var(--modal-background-color);
}

.status-container.accepted {
    background: #33b802;
}

.status-container.tentative {
    background: #f2c94c;
}

.status-container.declined {
    background: #eb5757;
}

.status-container .icon {
    height: 11px;
    width: 11px;
}

.event-modal .footer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: space-between;
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 15px;
    padding-right: 15px;
    border-top: 1px solid var(--border-color);
    padding-top: 10px;
    margin-bottom: -10px;
    font-size: 14px;
}

.event-modal .footer .right {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.event-modal .attendee .meta {
    display: flex;
    flex-direction: column;
}

.event-modal .attendee .organizer {
    color: var(--text-secondary);
    font-size: 10px;
}

.event-modal .property {
    display: flex;
    align-items: initial;
    column-gap: 20px;
    margin-bottom: 10px;
    line-height: 16px;
}

.event-modal .title .icon {
    height: 18px;
    width: 18px;
}

.event-modal .property .title {
    color: var(--modal-property-title-color);
    display: flex;
    align-items: initial;
    column-gap: 5px;
    font-weight: 500;
}

.event-modal .value {
    color: var(--text-primary);
    font-weight: 400;
    font-size: 13px;
    word-wrap: break-word;
    overflow: hidden;
}

.event-modal .value.zoom {
    color: #3b86fd;
    font-weight: 500;
    cursor: pointer;
}

.event-modal .value.google-meet {
    color: #1677ff;
    font-weight: 500;
    cursor: pointer;
}

.value a {
    color: #1677ff;
}

.footer .event-response-button {
    font-size: 13px;
    font-weight: 500;
    color: var(--button-color);
    cursor: pointer;
    background-color: var(--button-background-color);
    border-radius: 30px;
    padding: 2px 14px;
    display: inline-block;
    border: var(--button-border);
}

.footer .event-response-button.active {
    background-color: var(--calendar-response-button-background-color);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}