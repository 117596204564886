.ellie-menu {
    width: 265px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    box-shadow: 0 1px 7px rgb(0 0 0 / 17%);
    border-radius: 7px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-primary);
}

.ellie-menu-item {
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    white-space: pre-line;
    display: flex;
    align-items: center;
}


.ellie-menu-item-icon {
    height: 18px;
    width: 22px;
    margin-right: 3px;
    align-self: flex-start;
    color: #808080;
    margin-top: 3px;
    /* background-color: var(--text-primary); */
}

.ellie-menu-item .check-container {
    height: 18px;
    width: 25px;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #808080;
}

.ellie-menu-item-check {
    height: 20px;
    width: 20px;
    color: var(--primary-color);
}

.elie-menu-item-text {
    flex: 1;
}

.ellie-menu-item:hover {
    background-color: var(--option-hover-background-color);
}


.ant-popover-inner-content {
    padding: 0;
}

.ellie-menu-item-arrow {
    height: 18px;
    width: 22px;
    margin-top: 3px;
}

.ellie-menu-divider {
    width: 90%;
    /* margin-right: auto; */
    /* margin-left: auto; */
    height: 1px;
    background-color: var(--border-color);
    margin: 1px auto;
}

.ellie-menu-item.clickable {
    background-color: var(--time-estimate-background);
    border: 1px solid var(--option-border-color);
}

.ellie-menu-item.clickable:hover {
    background-color: var(--option-hover-background-color);
}

.elie-menu-item-subtitle {
    color: gray;
    margin-left: 4px;
}

.ellie-menu-header {
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    /* height: 50px; */
    padding: 4px 5px 9px 7px;
    align-items: center;
    flex-direction: row;
    /* height: 43px; */
    /* background-color: #d9d9d9; */
    margin-bottom: 2px;
}

.ellie-menu-header-text {
    font-size: 13px;
    font-weight: 600;
    color: var(--text-primary);
}

.ellie-menu-close-icon {
    height: 18px;
    width: 22px;

    color: #cdcdcd;
    /* background-color: #000000; */
    cursor: pointer;
}

.ellie-menu-close-icon:hover {
    color: var(--text-primary);
}

.ellie-menu-checkmark {
    height: 18px;
    width: 22px;
    align-self: flex-start;
    color: #808080;
    /* background-color: #000000; */
}


.specific-time-section {
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    padding: 7px 8px 10px 8px;
    row-gap: 6px;
    border: 1px solid var(--button-hover-background-color);
    background: var(--border-color);
    /* margin-top: 2px; */
    /* margin-bottom: 4px; */
    margin: 4px 3px 5px 3px;
}

.specific-time-section .enable {
    display: flex;
    align-items: center;
    column-gap: 10px;
    /* padding: 6px; */
    /* border-top: 1px solid #dadada; */
    /* margin-top: 5px; */
    justify-content: space-between;
    padding: 0px 3px;
}