.labels {
    margin-right: 10px;

}

.labels-title {
    margin-bottom: .25em;
}

.labels-container {
    overflow-y: auto;
    min-width: 150px;
    /* max-height: 120px; */
    flex: 1 1 auto;
    padding: 4px 6px 0px 6px;
}

.label-container {
    display: flex;
    align-items: center;
    padding: 5px 8px;
    border-radius: 6px;
    cursor: pointer;
    justify-content: space-between;
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-primary);
}

.label {
    display: flex;
    align-items: center;
}

.label-box {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-right: 5px;
    min-width: 9px;
    min-height: 9px;
}

.card-label span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label-container:hover {
    background-color: var(--option-hover-background-color);

}

.label-container.selected {
    background-color: var(--option-hover-background-color);

}

.label-selected-icon {
    height: 14px;
    color: #ababab;
}

.label-filter-button {
    /* background-color: #000000; */
    padding: 8px 16px;
    /* border-radius: 25px; */
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    /* margin-top: 10px; */
}

.label-filter-button:hover {
    color: #272EE8;
}


.floating-labels-button-icon {
    height: 18px;
    margin-right: 8px;
}

.floating-button-chevron {
    margin-left: 8px;
    height: 18px;


}

.labels-filter-container {
    border-radius: 6px;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    display: flex;
    border-radius: 7px;
    background: var(--modal-background-color);
    box-shadow: 0px 1px 3px rgba(128, 123, 123, 0.25);
    /* max-height: 250px; */
}

/* Updating label styles */

.update-label-container {
    display: flex;
    align-items: center;
    padding: 9px 8px;
    justify-content: space-between;
}

.delete-label-icon {
    height: 19px;
    color: #bbbbbb;
    cursor: pointer;
}

.delete-label-icon:hover {

    color: var(--text-primary);

}

.update-label-fields {
    display: flex;
    align-items: center;
    flex: auto;
    margin-right: 10px;
}


.lm-close {
    cursor: pointer;
    height: 20px;
    color: gray;
}

.lm-close:hover {
    color: black;
}

.label-container.edit {
    font-weight: 500;
    color: var(--primary-color);
    font-size: 13px;
}

.edit-labels-arrow-icon {
    height: 15px;
    margin-left: 4px;
}


.label-editable {
    width: 100%;
    height: 40px;
    padding: 0 11px 0 16px;
    box-shadow: inset 0 0 0 1px rgba(188, 188, 201, 0.18);
    column-gap: 16px;
    list-style: none;
    align-items: center;
    border-radius: 7px;
    background-color: var(--label-editable-background);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    display: flex;
}

.label-editable .meta {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 400;
    color: var(--text-primary);
}

.label-editable .action-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    cursor: pointer;
    border-radius: 5px;
    color: #7b7b7b;
}

.label-editable .action-menu:hover {
    background-color: var(--label-editable-action-menu-hover-background);
    color: var(--text-primary);
}

.label-editable-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.label-editable-icon {
    width: 15px;
    height: 15px;
}

.lm-body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.lm-body .lm-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin-bottom: 5px;
}

.lm {
    margin-bottom: 40px;
}

.lm-header .search-bar {
    width: 100%;
    max-width: 300px;
    height: 35px;
    padding: 0 11px 0 13px;
    /* box-shadow: inset 0 0 0 1px rgba(188, 188, 201, 0.18); */
    column-gap: 8px;
    list-style: none;
    align-items: center;
    border-radius: 7px;
    background-color: var(--search-bar-background);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    display: flex;
    border: var(--search-bar-border);
    /* color: #000000; */
}

.lm-header .search-bar .search-bar-icon {
    width: 20px;
    height: 20px;
    color: #afafaf;
}

.lm-header .search-bar .search-bar-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--settings-input-color);
    background-color: transparent;
}

.lm-header .search-bar .search-bar-input::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #afafaf;
}

.lm-header .create-button {
    /* width: 200px; */
    height: 35px;
    justify-content: center;
    padding: 10px 17px;
    /* box-shadow: inset 0 0 0 1px rgba(188, 188, 201, 0.18); */
    column-gap: 5px;
    /* list-style: none; */
    align-items: center;
    border-radius: 7px;
    color: #ffffff;
    background-color: #640ef5;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* justify-content: space-between; */
    display: flex;
    cursor: pointer;
    /* background: left; */
    font-size: 13px;
    font-weight: 500;
}

.lm-header .create-button .create-button-icon {
    width: 20px;
    height: 20px;
}

.action-menu-items {
    display: flex;
    align-items: center;
    column-gap: 3px;
}

.label-editable.editing {
    height: 50px;
}

/* Mobile */
@media (max-width: 768px) {
    .lm-header {
        row-gap: 10px;
    }

    .lm-header .search-bar {
        width: 60%;
    }
}

.glow-border {
    box-shadow: 0 0 10px #800080, 0 0 15px #800080, 0 0 20px #800080, 0 0 25px #800080, 0 0 35px #800080;
    border: 1px solid #800080;
  }